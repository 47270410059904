var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-btn',{attrs:{"block":"","color":"primary"},on:{"click":_vm.openDialogRole}},[_c('v-icon',{attrs:{"dark":"","left":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiPlusCircle)+" ")]),_vm._v(" "+_vm._s(_vm.$t('add'))+" Role ")],1)],1)],1)],1),_c('v-data-table',{staticClass:"text-no-wrap text-center",attrs:{"headers":_vm.headers,"item-key":"role","items":_vm.dataListRole,"items-per-page":10,"sort-desc":[true],"loading":_vm.loading,"multi-sort":"","must-sort":"","footer-props":{
      'items-per-page-text': _vm.$t('rowsPerPageLabel'),
    }},scopedSlots:_vm._u([{key:"item.avg_reply",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.avg_reply !== null ? _vm.msToTime(item.avg_reply) : '00:00:00.0')+" ")]}},{key:"item.role",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.role)+" ")]}},{key:"item.chat_mode",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.showChatMode(item.chat_mode))+" ")]}},{key:"item.enabled_channel",fn:function(ref){
    var item = ref.item;
return [(item.enabled_channel && item.enabled_channel.length === 0)?_c('div',{staticClass:"my-2"},[_vm._v(" "+_vm._s(item.limited_channel ? 'No Selected Channel' : 'All Channel')+" ")]):_c('div',{staticClass:"my-2"},_vm._l((item.enabled_channel),function(data,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(_vm.getChannelsName(data))+" ")])}),0)]}},{key:"item.enabled_menu",fn:function(ref){
    var item = ref.item;
return [(!item.limited_menu || item.enabled_menu.length === 0)?_c('div',{staticClass:"my-2"},[_vm._v(" "+_vm._s(item.limited_menu ? 'No Selected Menu' : 'All Menu')+" ")]):_c('div',{staticClass:"my-2"},_vm._l((item.enabled_menu),function(data,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(("" + (_vm.$t(data))))+" ")])}),0)]}},(_vm.user.role === 'Admin')?{key:"item.delete",fn:function(ref){
    var item = ref.item;
return [(item.role !== 'Admin' && item.role !== 'Operator')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dense":"","icon":""},on:{"click":function($event){return _vm.addSelectedChannel(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPencilOutline))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('user.edit')))])]):_vm._e(),(item.role !== 'Admin' && item.role !== 'Operator')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dense":"","icon":""},on:{"click":function($event){;(_vm.deleteUserdata = item), (_vm.confirmDeleteMessage = true)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDeleteOutline))])],1)]}}],null,true)},[_c('span',[_vm._v("Delete role")])]):_vm._e()]}}:null],null,true)},[_c('template',{slot:"no-data"},[_vm._v(" "+_vm._s(_vm.$t('noDataLabel'))+" ")])],2),_c('v-dialog',{attrs:{"width":"600px","persistent":""},model:{value:(_vm.dialogRole),callback:function ($$v) {_vm.dialogRole=$$v},expression:"dialogRole"}},[_c('v-card',[_c('v-card-actions',[_c('h3',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.editRole ? 'Edit Role' : 'Add Role'))]),_c('v-spacer')],1),_c('v-divider'),_c('v-card-text',[_c('v-text-field',{attrs:{"label":"Role","outlined":"","disabled":_vm.editableRole,"dense":"","placeholder":"Role","hide-details":""},model:{value:(_vm.currentItem.role),callback:function ($$v) {_vm.$set(_vm.currentItem, "role", $$v)},expression:"currentItem.role"}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{staticClass:"mt-6",attrs:{"dense":"","outlined":"","items":_vm.listChatMode,"menu-props":{ offsetY: true },"label":"Chat mode","hint":"Chat mode"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getText(item))+" ")]}},{key:"item",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getText(item))+" ")]}}]),model:{value:(_vm.currentItem.chat_mode),callback:function ($$v) {_vm.$set(_vm.currentItem, "chat_mode", $$v)},expression:"currentItem.chat_mode"}})],1)],1),_c('v-select',{attrs:{"items":_vm.listInstances,"label":"Enabled Channel","item-text":"label_server","outlined":"","item-value":"phone_number","multiple":""},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"mousedown":function($event){$event.preventDefault();},"click":_vm.toggle}},[_c('v-list-item-action',[_c('v-icon',[_vm._v(" "+_vm._s(_vm.iconSelect)+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Select All ")])],1)],1),_c('v-divider',{staticClass:"mt-2"})]},proxy:true}]),model:{value:(_vm.currentItem.enabled_channel),callback:function ($$v) {_vm.$set(_vm.currentItem, "enabled_channel", $$v)},expression:"currentItem.enabled_channel"}}),_c('v-select',{attrs:{"items":_vm.listMenu,"label":"Enabled Menu","outlined":"","multiple":""},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"mousedown":function($event){$event.preventDefault();},"click":_vm.toggleMenu}},[_c('v-list-item-action',[_c('v-icon',[_vm._v(" "+_vm._s(_vm.iconSelectMenu)+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Select All ")])],1)],1),_c('v-divider',{staticClass:"mt-2"})]},proxy:true}]),model:{value:(_vm.currentItem.enabled_menu),callback:function ($$v) {_vm.$set(_vm.currentItem, "enabled_menu", $$v)},expression:"currentItem.enabled_menu"}}),_c('v-snackbar',{staticStyle:{"z-index":"1 !important"},attrs:{"color":"error","top":true},scopedSlots:_vm._u([{key:"action",fn:function(ref){
    var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":_vm.closeErrorRoleEmptySnackbar}},'v-btn',attrs,false),[_vm._v(" "+_vm._s(_vm.$t('close'))+" ")])]}}]),model:{value:(_vm.errorRoleEmpty),callback:function ($$v) {_vm.errorRoleEmpty=$$v},expression:"errorRoleEmpty"}},[_vm._v(" "+_vm._s(_vm.joinedError)+" cannot be empty ")])],1),_c('v-divider',{}),_c('v-card-actions',{staticClass:"justify-end text-end"},[_c('v-spacer'),_c('v-btn',{staticClass:"mr-3",attrs:{"color":"#ffeeee"},on:{"click":_vm.closeDialog}},[_c('span',{staticClass:"error--text"},[_vm._v(_vm._s(_vm.$t('cancel')))])]),_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.saveRole}},[_vm._v(" "+_vm._s(_vm.$t('save'))+" ")])],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","width":"600px"},model:{value:(_vm.confirmDeleteMessage),callback:function ($$v) {_vm.confirmDeleteMessage=$$v},expression:"confirmDeleteMessage"}},[_c('v-card',[_c('v-card-actions',[_c('h3',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s('Warning!!!')+" ")]),_c('v-spacer')],1),_c('v-divider'),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.$t('OperatorsTranslate.confirmWant'))+" "+_vm._s(_vm.deleteUserdata.role)+" "+_vm._s(_vm.$t('OperatorsTranslate.meaningAction'))+" ")]),_c('v-divider',{}),_c('v-snackbar',{staticStyle:{"z-index":"1 !important"},attrs:{"color":"error","top":true},scopedSlots:_vm._u([{key:"action",fn:function(ref){
    var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":_vm.closeErrorRoleSnackbar}},'v-btn',attrs,false),[_vm._v(" "+_vm._s(_vm.$t('close'))+" ")])]}}]),model:{value:(_vm.errorRole),callback:function ($$v) {_vm.errorRole=$$v},expression:"errorRole"}},[_vm._v(" Please empty team member for role "),_c('strong',[_vm._v(_vm._s(_vm.deleteUserdata.role))])]),_c('v-card-actions',{staticClass:"justify-end text-end"},[_c('v-btn',{staticClass:"mr-3 mt-3",attrs:{"color":"#ffeeee"},on:{"click":function($event){;(_vm.confirmDeleteMessage = false), (_vm.errorRole = false)}}},[_c('span',{staticClass:"error--text"},[_vm._v(_vm._s(_vm.$t('cancel')))])]),_c('v-btn',{staticClass:"mt-3",attrs:{"color":"success"},on:{"click":function($event){return _vm.deleteUser(_vm.deleteUserdata)}}},[_vm._v(" OK ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }