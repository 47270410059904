<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col cols="12" md="3">
          <v-btn block color="primary" @click="openDialogRole">
            <v-icon dark left>
              {{ icons.mdiPlusCircle }}
            </v-icon>
            {{ $t('add') }} Role
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
    <v-data-table
      :headers="headers"
      item-key="role"
      :items="dataListRole"
      :items-per-page="10"
      :sort-desc="[true]"
      :loading="loading"
      multi-sort
      must-sort
      class="text-no-wrap text-center"
      :footer-props="{
        'items-per-page-text': $t('rowsPerPageLabel'),
      }"
    >
      <template #[`item.avg_reply`]="{ item }">
        {{ item.avg_reply !== null ? msToTime(item.avg_reply) : '00:00:00.0' }}
      </template>
      <template #[`item.role`]="{ item }">
        {{ item.role }}
      </template>
      <template #[`item.chat_mode`]="{ item }">
        {{ showChatMode(item.chat_mode) }}
      </template>
      <!-- <template #[`item.limited_channel`]="{ item }">
        <v-checkbox
          v-model="item.limited_channel"
          :disabled="item.view_assign_only || item.role === 'Admin' || item.role === 'Owner' || user.role !== 'Admin'"
        
          @click="addSelectedChannel(item)"
        >
        </v-checkbox>
      </template> -->
      <template #[`item.enabled_channel`]="{ item }">
        <div v-if="item.enabled_channel && item.enabled_channel.length === 0" class="my-2">
          {{ item.limited_channel ? 'No Selected Channel' : 'All Channel' }}
        </div>
        <div v-else class="my-2">
          <div v-for="(data, index) of item.enabled_channel" :key="index">
            {{ getChannelsName(data) }}
          </div>
        </div>
      </template>
      <template #[`item.enabled_menu`]="{ item }">
        <div v-if="!item.limited_menu || item.enabled_menu.length === 0" class="my-2">
          {{ item.limited_menu ? 'No Selected Menu' : 'All Menu' }}
        </div>
        <div v-else class="my-2">
          <div v-for="(data, index) of item.enabled_menu" :key="index">
            {{ `${$t(data)}` }}
          </div>
        </div>
      </template>
      <!-- <template #[`item.limited_menu`]="{ item }">
        <v-checkbox
          v-model="item.limited_menu"
          :disabled="item.view_assign_only || item.role === 'Admin' || item.role === 'Owner' || user.role !== 'Admin'"
          @click="addSelectedChannel(item)"
        >
        </v-checkbox>
      </template> -->
      <template v-if="user.role === 'Admin'" #[`item.delete`]="{ item }">
        <v-tooltip v-if="item.role !== 'Admin' && item.role !== 'Operator'" bottom>
          <template #activator="{ on, attrs }">
            <v-btn v-bind="attrs" dense icon v-on="on" @click="addSelectedChannel(item)">
              <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('user.edit') }}</span>
        </v-tooltip>

        <v-tooltip v-if="item.role !== 'Admin' && item.role !== 'Operator'" bottom>
          <template #activator="{ on, attrs }">
            <v-btn v-bind="attrs" dense icon v-on="on" @click=";(deleteUserdata = item), (confirmDeleteMessage = true)">
              <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
            </v-btn>
          </template>
          <span>Delete role</span>
        </v-tooltip>

        <!-- <v-icon
            dark
            left
          >
            {{ icons.mdiPencilOutline }}
          </v-icon> -->
        <!-- <v-icon
          dark
          left
          @click=";(deleteUserdata = item), (confirmDeleteMessage = true)"
        >
          {{ item.role === 'Admin' ? '' : icons.mdiDeleteOutline }}
        </v-icon> -->
      </template>
      <!-- Channel -->
      <template slot="no-data">
        {{ $t('noDataLabel') }}
      </template>
    </v-data-table>
    <!-- Role Settiings -->
    <v-dialog v-model="dialogRole" width="600px" persistent>
      <v-card>
        <v-card-actions>
          <h3 class="font-weight-bold">{{ editRole ? 'Edit Role' : 'Add Role' }}</h3>
          <v-spacer></v-spacer>
          <!-- <v-btn
            color="error"
            class="mr-3 hide-buton"
            @click="dialogSelectChannel = false"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
            color="success"
            class="hide-buton"
            @click="saveChannel"
          >
            {{ $t('save') }}
          </v-btn> -->
        </v-card-actions>
        <v-divider />

        <v-card-text>
          <v-text-field
            v-model="currentItem.role"
            label="Role"
            outlined
            :disabled="editableRole"
            dense
            placeholder="Role"
            hide-details
          ></v-text-field>
          <v-row>
            <v-col cols="12">
              <v-select
                class="mt-6"
                dense
                outlined
                :items="listChatMode"
                :menu-props="{ offsetY: true }"
                label="Chat mode"
                v-model="currentItem.chat_mode"
                hint="Chat mode"
              >
                <template v-slot:selection="{ item }">
                  {{ getText(item) }}
                </template>
                <template v-slot:item="{ item }">
                  {{ getText(item) }}
                </template>
              </v-select>
            </v-col>
          </v-row>
          <!-- <v-switch
            v-if="!currentItem.view_assign_only"
            v-model="currentItem.limited_channel"
            :disabled="currentItem.role === 'Admin'"
            :label="$t('history.viewAssignOnly')"
          >
          </v-switch> -->
          <!-- <v-switch
            v-model="currentItem.limited_channel"
            :disabled="currentItem.role === 'Admin' || currentItem.view_assign_only"
            :label="$t('history.limitedChannel')"
          >
          </v-switch>
          <div v-if="currentItem.limited_channel">
            <div v-for="(data, index) of listInstances" :key="index">
              <v-checkbox
                v-model="dataSubId[index]"
                :label="
                  data.label.startsWith('-') || data.label.includes('Default') || data.label.includes('62')
                    ? data.label_server
                    : data.label
                "
              >
              </v-checkbox>
            </div>
          </div> -->
          <v-select
            v-model="currentItem.enabled_channel"
            :items="listInstances"
            label="Enabled Channel"
            item-text="label_server"
            outlined
            item-value="phone_number"
            multiple
          >
            <template v-slot:prepend-item>
              <v-list-item ripple @mousedown.prevent @click="toggle">
                <v-list-item-action>
                  <v-icon>
                    {{ iconSelect }}
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title> Select All </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-2"></v-divider>
            </template>
          </v-select>

          <v-select v-model="currentItem.enabled_menu" :items="listMenu" label="Enabled Menu" outlined multiple>
            <template v-slot:prepend-item>
              <v-list-item ripple @mousedown.prevent @click="toggleMenu">
                <v-list-item-action>
                  <v-icon>
                    {{ iconSelectMenu }}
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title> Select All </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-2"></v-divider>
            </template>
          </v-select>

          <v-snackbar v-model="errorRoleEmpty" color="error" :top="true" style="z-index: 1 !important">
            {{ joinedError }} cannot be empty
            <template #action="{ attrs }">
              <v-btn text v-bind="attrs" @click="closeErrorRoleEmptySnackbar">
                {{ $t('close') }}
              </v-btn>
            </template>
          </v-snackbar>
          <!-- <v-switch
            v-if="!currentItem.view_assign_only"
            v-model="currentItem.limited_menu"
            :disabled="currentItem.role === 'Admin'"
            label="Limited Menu"
          >
          </v-switch>
          <div v-if="currentItem.limited_menu">
            <div v-for="(data, index) of listMenu" :key="index">
              <v-checkbox
                v-model="dataMenu[index]"
                :disabled="!currentItem.limited_menu || currentItem.view_assign_only"
                :label="$t(data)"
              >
              </v-checkbox>
            </div>
          </div> -->
        </v-card-text>
        <v-divider class="" />
        <v-card-actions class="justify-end text-end">
          <v-spacer></v-spacer>
          <v-btn color="#ffeeee" class="mr-3" @click="closeDialog">
            <span class="error--text">{{ $t('cancel') }}</span>
          </v-btn>
          <v-btn color="success" class="" @click="saveRole">
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="confirmDeleteMessage" persistent width="600px">
      <v-card>
        <v-card-actions>
          <h3 class="font-weight-bold">
            {{ 'Warning!!!' }}
          </h3>
          <v-spacer></v-spacer>

          <!-- <v-btn
            color="error"
            class="mr-3 hide-buton"
            @click="confirmDeleteMessage = false"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
            color="success"
            class="hide-buton"
            @click="deleteUser(deleteUserdata)"
          >
            OK
          </v-btn> -->
        </v-card-actions>
        <v-divider />

        <!-- <div class="d-flex justify-center mt-3">
            <v-alert
                dense
                outlined
                type="warning"
                dismissible
                v-if="errorRole"
                v-model="errorRole"
                max-width="340px"
                max-height="50px"
                class="text-xs text-center"
                >
                  Please empty team member for role <strong>{{deleteUserdata.role}}</strong> 
            </v-alert>  
          </div> -->
        <v-card-text>
          {{ $t('OperatorsTranslate.confirmWant') }} {{ deleteUserdata.role }}
          {{ $t('OperatorsTranslate.meaningAction') }}
        </v-card-text>
        <v-divider class="" />
        <v-snackbar v-model="errorRole" color="error" :top="true" style="z-index: 1 !important">
          Please empty team member for role <strong>{{ deleteUserdata.role }}</strong>
          <template #action="{ attrs }">
            <v-btn text v-bind="attrs" @click="closeErrorRoleSnackbar">
              {{ $t('close') }}
            </v-btn>
          </template>
        </v-snackbar>
        <v-card-actions class="justify-end text-end">
          <v-btn color="#ffeeee" class="mr-3 mt-3" @click=";(confirmDeleteMessage = false), (errorRole = false)">
            <span class="error--text">{{ $t('cancel') }}</span>
          </v-btn>
          <v-btn color="success" class="mt-3" @click="deleteUser(deleteUserdata)"> OK </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- <v-dialog v-model="errorRole" persistent width="600px">
      <v-card>
        <v-card-actions>
          <h3 class="font-weight-bold">
            {{ 'Warning!!!' }}
          </h3>
          <v-spacer></v-spacer>
        </v-card-actions>
        <v-divider />
        <v-card-text>
          Please empty team member for role <strong>{{deleteUserdata.role}}</strong> 
        </v-card-text>
        <v-divider class="" />
        <v-card-actions class="justify-end text-end">
          <v-btn color="#ffeeee" class="mr-3" @click="errorRole = false">
            <span class="error--text">{{ $t('close') }}</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
  </v-card>
</template>

<script>
import {
  mdiCalendarMonth,
  mdiChartBar,
  mdiCheckboxBlankOutline,
  mdiCloseBox,
  mdiCloseCircle,
  mdiDeleteOutline,
  mdiExport,
  mdiEyeOutline,
  mdiMagnify,
  mdiMinusBox,
  mdiPencilOutline,
  mdiPlusCircle,
  mdiUnfoldMoreHorizontal,
} from '@mdi/js'

import { default as clientPocketBase } from '@/helpers/pocketbase'
export default {
  data: () => ({
    confirmDeleteMessage: false,
    deleteUserdata: {},
    editableRole: false,
    dataSubId: [],
    dataMenu: [],
    loading: false,
    dataListRole: [],
    dialogRole: false,
    editRole: false,
    flagId: false,
    currentItem: {
      last_role_name: '',
      role: '',
      auto_assign: false,
      chat_mode: 'Full Access',
      view_assign_only: false,
      limited_channel: false,
      enabled_channel: [],
      limited_menu: false,
      enabled_menu: [],
    },
    errorRole: false,
    errorRoleEmpty: false,
    // listChatMode: ['Full Access', 'View Only', 'Write to assigned only', 'Read and Write to assigned only'],
    listChatMode: ['Full Access', 'Read and Write to assigned only'],
    dataUsers: [],
    selectedRoleId: '',
    joinedError: '',
    listInstances: [],
  }),
  async mounted() {
    const instances = await this.$store.dispatch('auth/getAllInstances')
    this.listInstances = instances?.value || []
    // this.dataListRole = await clientPocketBase.collection('crm_role').getFullList(200, { $autoCancel: false })
    await this.fetchRoles()
    // const loadingSyncPg = await this.$store.dispatch('global/getSettingsByKey', {
    //   sub_id: this.user.sub_id,
    //   token: this.user.token,
    //   key: 'role_settings',
    // })
    // if (loadingSyncPg.status) {
    //   this.dataListRole = loadingSyncPg.data.value
    // } else {
    //   this.dataListRole = []
    // }
    // await this.$store.dispatch('auth/fetchListUser', {
    //   sub_id: this.user.sub_id,
    // })
  },
  watch: {
    viewAssign() {
      if (this.currentItem.view_assign_only) {
        this.currentItem.limited_channel = false
        this.currentItem.limited_menu = false
        this.dataMenu = []
        this.dataSubId = []
      }
    },
    limitedChannel() {
      if (!this.currentItem.limited_channel) {
        this.dataSubId = []
      }
    },
    limitedMenu() {
      if (!this.currentItem.limited_menu) {
        this.dataMenu = []
      } else {
        if (this.currentItem.enabled_menu.length === 0) {
          this.dataMenu =
            this.user.package === 'basic'
              ? ['Chat', 'Send Broadcast', 'Contact List', 'Channels', 'Broadcasts']
              : this.user.package === 'pro'
              ? ['Chat', 'Send Broadcast', 'Contact List', 'Channels', 'Inbox', 'Broadcasts']
              : this.user.package === 'business'
              ? ['Chat', 'Send Broadcast', 'Contact List', 'Channels', 'Inbox', 'Broadcasts', 'Users']
              : [
                  'Chat',
                  'Send Broadcast',
                  'Contact List',
                  'Channels',
                  'Inbox',
                  'Broadcast Timeline',
                  'Template',
                  'Label',
                  'Chat Data',
                  'Broadcasts',
                  'Users',
                  'Business+',
                  'My Apps',
                  'E-Shop',
                  'E-Form',
                  'Campaign Tracker',
                  'Login by Whatsapp',
                  'Statistics',
                  'Broadcast Statistic',
                  'User Perfomance',
                  'Chat Statistic',
                ]
        }
      }
    },
  },
  computed: {
    viewAssign() {
      return this.currentItem.view_assign_only
    },
    limitedChannel() {
      return this.currentItem.limited_channel
    },
    limitedMenu() {
      return this.currentItem.limited_menu
    },
    listWorkspaces() {
      return this.$store.getters['auth/getListWorkspaces']
    },
    dataListUsers() {
      return this.$store.getters['auth/getListUsers']
    },
    // listInstances() {
    //   const inst = this.$store.getters['auth/getListInstance']
    //   if (inst) {
    //     return inst.value
    //   } else {
    //     return []
    //   }
    // },
    listMenu() {
      return this.user.package === 'basic'
        ? ['Chat', 'Send Broadcast', 'Contact List', 'Channels', 'Broadcasts']
        : this.user.package === 'pro'
        ? ['Chat', 'Send Broadcast', 'Contact List', 'Channels', 'Inbox', 'Broadcasts']
        : this.user.package === 'business'
        ? ['Chat', 'Send Broadcast', 'Contact List', 'Channels', 'Inbox', 'Broadcasts', 'Users']
        : ['Channels', 'Chat', 'Broadcasts', 'Statistics', 'Contacts', 'Templates', 'My Assets', 'Chatbot']
    },
    user() {
      return this.$store.getters['auth/getUser']
    },
    headers() {
      return [
        { text: this.$t('action'), value: 'delete', sortable: false },
        { text: this.$t('history.role'), value: 'role', sortable: true },
        // { text: this.$t('history.customerServed'), value: 'customer_served', sortable: true, align: 'center' },
        // { text: this.$t('history.avgReply'), value: 'avg_reply', sortable: true },
        // { text: this.$t('history.autoAssign'), value: 'auto_assign', sortable: false },
        // { text: this.$t('history.viewAssignOnly'), value: 'view_assign_only', sortable: false, align: 'center' },
        // { text: this.$t('history.limitedChannel'), value: 'limited_channel', sortable: false, align: 'center' },
        { text: 'Chat Mode', value: 'chat_mode', sortable: true, align: 'center' },
        { text: this.$t('history.channelList'), value: 'enabled_channel', sortable: false, align: 'center' },
        // { text: this.$t('history.limitedMenu'), value: 'limited_menu', sortable: false, align: 'center' },
        { text: this.$t('history.enabledMenu'), value: 'enabled_menu', sortable: false, align: 'center' },
      ]
    },
    selectedAll() {
      if (this.currentItem.enabled_channel) {
        return this.currentItem.enabled_channel?.length === this.listInstances.length
      }
      return false
    },
    selectedSome() {
      return this.currentItem.enabled_channel?.length > 0 && !this.selectedAll
    },
    selectedAllMenu() {
      if (this.currentItem.enabled_menu) {
        return this.currentItem.enabled_menu.length === this.listMenu.length
      }
      return false
    },
    selectedSomeMenu() {
      return this.currentItem.enabled_menu.length > 0 && !this.selectedAll
    },
    iconSelect() {
      if (this.selectedAll) return this.icons.mdiCloseBox
      if (this.selectedSome) return this.icons.mdiMinusBox
      return this.icons.mdiCheckboxBlankOutline
    },
    iconSelectMenu() {
      if (this.selectedAllMenu) return this.icons.mdiCloseBox
      if (this.selectedSomeMenu) return this.icons.mdiMinusBox
      return this.icons.mdiCheckboxBlankOutline
    },
  },
  methods: {
    closeErrorRoleSnackbar() {
      this.errorRole = false
      this.confirmDeleteMessage = false
    },
    closeErrorRoleEmptySnackbar() {
      this.errorRoleEmpty = false
      this.joinedError = ''
    },
    toggle() {
      this.$nextTick(() => {
        if (this.selectedAll) {
          this.currentItem.enabled_channel = []
        } else {
          this.currentItem.enabled_channel = this.listInstances.map(el => el.phone_number)
        }
      })
    },
    toggleMenu() {
      this.$nextTick(() => {
        if (this.selectedAllMenu) {
          this.currentItem.enabled_menu = []
        } else {
          this.currentItem.enabled_menu = this.listMenu.slice()
        }
      })
    },
    showChatMode(chatMode) {
      if (chatMode === 'Full Access' || !chatMode) return 'Full Access (all contacts)'
      if (chatMode === 'View Only') return 'Read Only (all contacts)'
      if (chatMode === 'Write to assigned only') return 'Restrict Reply (Assigned Contact(s))'
      if (chatMode === 'Read and Write to assigned only') return 'Restrict Read & Reply (Assigned Contact(s))'
      return chatMode
    },
    getText(chatMode) {
      if (chatMode.includes('assigned')) return `${chatMode} (Assigned Contact(s))`
      return `${chatMode} (All contacts)`
    },
    async deleteUser(data) {
      this.loading = true
      // let foundRole = this.dataListUsers.some(element => element.role === data.role)
      //console.log(this.deleteUserdata, 'INI COY')
      let foundRole

      try {
        foundRole = await clientPocketBase
          .collection('users')
          .getFirstListItem(`crmRole = '${data.id}' && sub_id = '${this.user.sub_id}'`, { $autoCancel: false })
      } catch (error) {
        console.log(error, error.data, 'error foundRole')
      }
      if (foundRole) {
        this.errorRole = true
        this.loading = false
        return
      }
      try {
        const deleteRole = await clientPocketBase.collection('crm_role').delete(data.id)
        // console.log(deleteRole, 'cok<')
      } catch (error) {
        console.log(error, error.data, 'cuy<')
      }
      await this.fetchRoles()
      this.closeDialog()
      this.loading = false
      this.deleteUserdata = {}

      // if (!foundRole) {
      //   const res = await this.$store.dispatch('auth/deleteRole', {
      //     role: data.role,
      //     key: 'role_settings',
      //     sub_id: this.user.sub_id,
      //   })
      //   this.closeDialog()
      //   this.deleteUserdata = {}
      //   this.loading = false
      // } else {
      // this.confirmDeleteMessage = true
      // this.errorRole = true
      // this.loading = false
      // }
    },
    async addSelectedChannel(item) {
      if (item.role === 'Admin') {
        this.editableRole = true
      } else {
        this.editableRole = false
      }
      this.editRole = true
      this.dialogRole = true
      this.selectedRoleId = item.id
      // this.dataUsers = this.$store.getters['auth/getListUsers']
      this.currentItem = {
        last_role_name: item.role,
        ...item,
      }
      if (this.flagId) {
        let newEnabled = []
        this.currentItem.enabled_channel.forEach(el => {
          newEnabled.push(this.listInstances.find(ele => ele._id === el).phone_number)
        })
        this.flagId = false
      }
      this.listInstances.forEach((instance, index) => {
        this.dataSubId[index] = item.enabled_channel && item.enabled_channel.findIndex(x => x === instance._id) > -1
      })
      //console.log(this.flagId, 'INI DIA<<<')
      this.listMenu.forEach((menu, index) => {
        this.dataMenu[index] = item.enabled_menu && item.enabled_menu.findIndex(x => x === menu) > -1
      })
    },
    async saveRole() {
      try {
        let arrError = []
        if (!this.currentItem.role) {
          arrError.push('Role')
        }
        if (!this.currentItem.enabled_channel?.length) {
          arrError.push('Channel')
        }
        if (!this.currentItem.enabled_menu.length) {
          arrError.push('Menu')
        }
        if (arrError.length) throw arrError
        this.dialogRole = false
        let limitedChannel = false
        let limitedMenu = false

        if (
          this.currentItem.enabled_channel &&
          this.currentItem.enabled_channel?.length > 0 &&
          this.currentItem.enabled_channel?.length !== this.listInstances?.length
        ) {
          limitedChannel = true
        }
        if (this.currentItem.enabled_channel?.length === this.listInstances?.length) {
          const result = this.currentItem.enabled_channel.every(
            (value, index) => this.listInstances[index] && this.listInstances[index].phone_number === value,
          )
          if (!result) {
            limitedChannel = true
          }
        }
        if (
          this.currentItem.enabled_menu &&
          this.currentItem.enabled_menu.length > 0 &&
          this.currentItem.enabled_menu.length < this.listMenu?.length
        ) {
          limitedMenu = true
        }
        if (this.editRole) {
          const editedRole = await clientPocketBase.collection('crm_role').update(this.selectedRoleId, {
            sub_id: this.user.sub_id,
            role: this.currentItem.role,
            chat_mode: this.currentItem.chat_mode,
            limited_channel: limitedChannel,
            enabled_channel: this.currentItem.enabled_channel,
            limited_menu: limitedMenu,
            enabled_menu: this.currentItem.enabled_menu,
          })
          this.selectedRoleId = ''
        } else if (!this.editRole) {
          const newRole = await clientPocketBase.collection('crm_role').create({
            sub_id: this.user.sub_id,
            role: this.currentItem.role,
            chat_mode: this.currentItem.chat_mode,
            limited_channel: limitedChannel,
            enabled_channel: this.currentItem.enabled_channel,
            limited_menu: limitedMenu,
            enabled_menu: this.currentItem.enabled_menu,
          })
        }
        await this.fetchRoles()

        this.editableRole = false
        this.closeDialog()
        this.currentItem = JSON.parse(
          JSON.stringify({
            last_role_name: '',
            role: '',
            auto_assign: false,
            chat_mode: 'Full Access',
            view_assign_only: false,
            limited_channel: false,
            enabled_channel: this.listInstances,
            limited_menu: false,
            enabled_menu: this.listMenu,
          }),
        )
      } catch (error) {
        if (Array.isArray(error)) {
          this.errorRoleEmpty = true
          this.joinedError = error.join(', ')
        }
      }
    },
    async fetchRoles() {
      this.dataListRole = await clientPocketBase.collection('crm_role').getFullList(200, { $autoCancel: false })
    },
    async closeDialog() {
      this.confirmDeleteMessage = false
      this.deleteUserdata = {}
      this.editableRole = false
      this.loading = true
      this.editRole = false
      // const loadingSyncPg = await this.$store.dispatch('global/getSettingsByKey', {
      //   sub_id: this.user.sub_id,
      //   token: this.user.token,
      //   key: 'role_settings',
      // })
      // if (loadingSyncPg.status) {
      //   this.dataListRole = loadingSyncPg.data.value
      // } else {
      //   this.dataListRole = []
      // }
      this.loading = false
      this.dialogRole = false
    },
    openDialogRole() {
      this.editRole = false
      this.currentItem = JSON.parse(
        JSON.stringify({
          last_role_name: '',
          role: '',
          auto_assign: false,
          chat_mode: 'Full Access',
          view_assign_only: false,
          limited_channel: false,
          enabled_channel: this.listInstances.map(ele => ele.phone_number),
          limited_menu: false,
          enabled_menu: this.listMenu,
        }),
      )
      this.dataUsers = this.$store.getters['auth/getListUsers']
      this.dialogRole = true
    },
    getChannelsName(id) {
      let obj = {}
      if (this.listInstances.find(el => el._id === id)) {
        this.flagId = true
        obj = this.listInstances.find(el => el._id === id)
      } else {
        this.flagId = false
        obj = this.listInstances.find(el => el.phone_number === id)
      }
      if (obj) {
        return (
          (obj.label.startsWith('-') || obj.label.includes('Default') || obj.label.includes('62')
            ? obj.label_server
            : obj.label) +
          ' (' +
          obj.phone_number +
          ')'
        )
      }
      return ''
    },
  },
  setup() {
    return {
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiPlusCircle,
        mdiExport,
        mdiChartBar,
        mdiEyeOutline,
        mdiCloseCircle,
        mdiPencilOutline,
        mdiCalendarMonth,
        mdiCheckboxBlankOutline,
        mdiMinusBox,
        mdiCloseBox,
        mdiUnfoldMoreHorizontal,
      },
    }
  },
}
</script>

<style>
.v-input__slot {
  align-items: center;
  justify-content: center;
}
.v-data-table-header__icon {
  opacity: 1;
}
</style>
